import React from 'react';
import { Card, EmptyState } from "@shopify/polaris"

export default function ImageContentPreview({ content }) {
  let borderRadius = "5px"
  switch (content.borderRadius) {
    case "none":
      borderRadius = "0px"
      break
    case "base":
      borderRadius = "5px"
      break
    case "small":
      borderRadius = "2px"
      break
    case "large":
      borderRadius = "10px"
      break
    case "fullyRounded":
      borderRadius = "36px"
      break
  }
  return (
    <div>
      {content.img ? (
        <div
          style={{
            textAlign: content.alignment,
            position: "relative",
            //paddingBottom: `calc(100%/${content.aspectRatio})`,
          }}
        >
          <img
            src={content.img}
            alt="Selected image"
            style={{
              maxWidth: "100%",
              objectFit: content.fit,
              /*
              width: "100%",
              height: "100%",
              position: "absolute",
              top: "0px",
              left: "0px",
              */
              borderRadius: borderRadius,
              border: "none",
            }}
          />
        </div>
      ) : (
        <EmptyState>
          <p>Your images will appear here </p>
        </EmptyState>
      )}
    </div>
  )
}
